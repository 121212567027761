<template>
    <div class="container-fluid">
        <div>
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">AI 이미지 에디터</h4>
                        <p class="mb-0">이미지 업로드는 image/jpeg 형식으로 업로드 해주세요.</p>
                    </div>
                    <!-- <router-link :to="link" class="btn btn-primary add-list"><i class="las la-plus mr-3"></i>+{{linktext}}</router-link> -->
                </div>
            </div>
        </div>
        <button v-b-modal.cut-modal type="button" class="btn btn-primary mr-2 mt-1">이미지 확장</button>
        <button v-b-modal.inpainting-modal type="button" class="btn btn-primary mr-2 mt-1">이미지 인페이팅</button>
        <button v-b-modal.delete-background-modal type="button" class="btn btn-primary mr-2 mt-1">배경 제거</button>
        <button v-b-modal.image-enlargement-modal type="button" class="btn btn-primary mr-2 mt-1">이미지 확대</button>
        <button v-b-modal.image-delete-object-modal type="button" class="btn btn-primary mr-2 mt-1">이미지 개체제거</button>
        <button v-b-modal.reimagine-modal type="button" class="btn btn-primary mr-2 mt-1">이미지 재구성</button>
        <button v-b-modal.delete-text type="button" class="btn btn-primary mr-2 mt-1">이미지 텍스트 삭제</button>
        <button v-b-modal.text-image type="button" class="btn btn-primary mr-2 mt-1">텍스트 이미지 변경</button>
        <button v-b-modal.replace-background type="button" class="btn btn-primary mr-2 mt-1">이미지 배경 변경</button>
        <b-modal id="cut-modal" size="xl" title="이미지 확장" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <input type="file" @change="onFileChange">
            <div v-if="imageUrl">
                <img :src="imageUrl" alt="Uploaded Image" class="image">
            </div>

            <div>
                <label for="extend-left">왼쪽으로 확장할 픽셀 수 (최대 2000):</label>
                <input type="number" id="extend-left" v-model="extendLeft">
                <br>
                <label for="extend-right">오른쪽으로 확장할 픽셀 수 (최대 2000):</label>
                <input type="number" id="extend-right" v-model="extendRight">
                <br>
                <label for="extend-up">위로 확장할 픽셀 수 (최대 2000):</label>
                <input type="number" id="extend-up" v-model="extendUp">
                <br>
                <label for="extend-down">아래로 확장할 픽셀 수 (최대 2000):</label>
                <input type="number" id="extend-down" v-model="extendDown">
                <br>

            </div>
            <button class="btn btn-primary mr-2 mb-4" @click="extendImage">이미지 확장 생성</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')" class="image">
        </b-modal>


        <b-modal id="inpainting-modal" size="xl" title="이미지 인페이팅" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <div>
                <div>
                    <div>
                        <input type="file" @change="onFileChange" style="margin-bottom: 20px">
                    </div>
                    <canvas ref="canvas" style="max-width: 100%" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"></canvas>
                    <div>
                        <div v-if="imageUrl">인페이팅 영역을 선택해 주세요.</div>
                        <input v-if="maskImageURL" style="margin-bottom: 16px" type="text" class="form-control round" placeholder="변경하고싶은 설명을 기재해주세요." v-model="textPrompt">
                        <button v-if="maskImageURL" class="btn btn-primary mr-2 mb-4" @click="generateMaskImage(true)" :disabled="!imageFile || !selectedRegion">이미지 인페이팅 실행</button>
                    </div>
                </div>
            </div>
            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')" class="image">
        </b-modal>

        <b-modal id="image-delete-object-modal" size="xl" title="이미지 개체제거" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <div>
                <div>
                    <div>
                        <input type="file" @change="onFileChange" style="margin-bottom: 20px">
                    </div>
                    <canvas ref="canvas" style="max-width: 100%" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"></canvas>
                    <div>
                        <div v-if="imageUrl">제거 영역을 선택해 주세요.</div>
                        <button v-if="maskImageURL" class="btn btn-primary mr-2 mb-4" @click="generateMaskImage(true, 'delete')" :disabled="!imageFile || !selectedRegion">이미지에서 개체 제거</button>
                    </div>
                </div>
            </div>
            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')" class="image">
        </b-modal>
        <b-modal id="delete-background-modal" size="xl" title="배경 제거" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <input type="file" @change="onFileChange">
            <div v-if="imageUrl">
                <img :src="imageUrl" alt="Uploaded Image" class="image">
            </div>
            <button class="btn btn-primary mr-2 mb-4" @click="deleteBackground">배경제거</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
        </b-modal>

        <b-modal id="image-enlargement-modal" size="xl" title="이미지 확대" ok-title="확인" cancel-title="닫기">
            <input type="file" @change="onFileChange">
            <div v-if="imageUrl">
                <img :src="imageUrl" alt="Uploaded Image" class="image">
            </div>

            <div>
                <label for="extend-left">width 픽셀 수 (최대 4096):</label>
                <input type="number" id="extend-left" v-model="targetWidth">
                <br>
                <label for="extend-right">height 픽셀 수 (최대 4096):</label>
                <input type="number" id="extend-right" v-model="targetHeight">
                <br>
            </div>
            <button class="btn btn-primary mr-2 mb-4" @click="extendImage">이미지 확대 생성</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')" class="image">
        </b-modal>

        <b-modal id="reimagine-modal" size="xl" title="이미지 재구성" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <div>최대 높이와 너비는는 1024px입니다.</div>
            <input type="file" @change="onFileChange">
            <div v-if="imageUrl">
                <img :src="imageUrl" alt="Uploaded Image" class="image">
            </div>
            <button class="btn btn-primary mr-2 mb-4" @click="reimagine">이미지 재구성</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
        </b-modal>
        <b-modal id="delete-text" size="xl" title="이미지 텍스트 삭제" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <input type="file" @change="onFileChange">
            <div v-if="imageUrl">
                <img :src="imageUrl" alt="Uploaded Image" class="image">
            </div>
            <button v-if="imageUrl" class="btn btn-primary mr-2 mb-4" @click="deleteText">텍스트 삭제</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
        </b-modal>
        <b-modal id="text-image" size="xl" title="텍스트 이미지 변경" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <input style="margin-bottom: 16px" type="text" class="form-control round" placeholder="만들고 싶은 이미지를 설명해 주세요" v-model="textPrompt">
            <button class="btn btn-primary mr-2 mb-4" @click="textImage">이미지 생성</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
        </b-modal>

        <b-modal id="replace-background" size="xl" title="이미지 배경 변경" ok-title="확인" cancel-title="닫기" @hidden="onClose">
            <input type="file" @change="onFileChange">
            <div v-if="imageUrl">
                <img :src="imageUrl" alt="Uploaded Image" class="image">
            </div>
            <input v-if="imageUrl" style="margin-bottom: 16px" type="text" class="form-control round" placeholder="추가하고 싶은 배경을 설명해 주세요" v-model="textPrompt">
            <button v-if="imageUrl" class="btn btn-primary mr-2 mb-4" @click="replaceBackground">이미지 생성</button>

            <div v-if="apiResponse">
                <img :src="apiResponse" alt="Extended Image" @click="imageDownload" class="image">
                <div>
                    <button class="btn btn-primary mr-2 mb-4" @click="imageDownload">다운로드</button>
                </div>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
        </b-modal>
    </div>
</template>
<script>
export default  {
    name: 'AIimage',
    data() {
        return {
            imageUrl: null,
            imageFile: null,
            extendLeft: 0,
            extendRight: 0,
            extendUp: 0,
            extendDown: 0,
            apiResponse: null,
            outputImageUrl: null,
            isDragging: false,
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            maskImageURL: '',
            maskFile: '',
            isDrawing: false,
            lastX: 0,
            lastY: 0,
            selectedRegion: null,
            textPrompt: '',
            apiKey: '1fb1c6bfb2745669796d6a1ea54b0c5f931e78f1bfc5d19e57f219a55de7d388f9c466495c3a2fe9d0ab5b52c0976783',
            targetWidth: 0,
            targetHeight: 0,
            showspinner: false,
        }
    },

    methods: {
        onImageLoad() {
            this.canvas = this.$refs.canvas;
            this.ctx = this.canvas.getContext('2d');
            this.canvas.width = this.$refs.image.width;
            this.canvas.height = this.$refs.image.height;
        },

        onFileChange(event) {
            this.imageFile = event.target.files[0];
            this.imageUrl = URL.createObjectURL(this.imageFile);
            this.drawSelectedRegion();
        },
        async extendImage() {
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);

            form.append('extend_left', this.extendLeft);
            form.append('extend_right', this.extendRight);
            form.append('extend_up', this.extendUp);
            form.append('extend_down', this.extendDown);

            try {
                const response = await fetch('https://clipdrop-api.co/uncrop/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },
        imageDownload() {
            const a = document.createElement('a');
            a.href = this.apiResponse;
            a.download = 'extended_image.jpg';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        onMouseDown(event) {
            this.isDrawing = true;
            const rect = this.$refs.canvas.getBoundingClientRect();
            this.startX = event.clientX - rect.left;
            this.startY = event.clientY - rect.top;
            this.endX = this.startX;
            this.endY = this.startY;
        },
        onMouseMove(event) {
            if (this.isDrawing) {
                const rect = this.$refs.canvas.getBoundingClientRect();
                this.endX = event.clientX - rect.left;
                this.endY = event.clientY - rect.top;
                this.drawSelectedRegion();
            }
        },
        onMouseUp() {
            this.isDrawing = false;
            this.selectedRegion = {
                startX: Math.min(this.startX, this.endX),
                startY: Math.min(this.startY, this.endY),
                endX: Math.max(this.startX, this.endX),
                endY: Math.max(this.startY, this.endY)
            };
            this.generateMaskImage();
        },
        drawSelectedRegion() {
            if (this.$refs.canvas) {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext("2d");
                const startX = this.startX;
                const startY = this.startY;
                const endX = this.endX;
                const endY = this.endY;

                if (this.imageFile) {
                    const img = new Image();
                    img.onload = () => {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);

                        ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
                        ctx.fillRect(startX, startY, endX - startX, endY - startY);
                    };
                    img.src = URL.createObjectURL(this.imageFile);
                }
            }
        },
        generateMaskImage(start, deleteStart) {
            if (!this.selectedRegion || !this.imageFile) {
                return;
            }

            const startX = Math.min(this.selectedRegion.startX, this.selectedRegion.endX);
            const startY = Math.min(this.selectedRegion.startY, this.selectedRegion.endY);
            const width = Math.abs(this.selectedRegion.endX - this.selectedRegion.startX);
            const height = Math.abs(this.selectedRegion.endY - this.selectedRegion.startY);

            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                for (let y = 0; y < canvas.height; y++) {
                    for (let x = 0; x < canvas.width; x++) {
                        const index = (y * canvas.width + x) * 4;
                        if (x >= startX && x <= startX + width && y >= startY && y <= startY + height) {
                            data[index] = 255;
                            data[index + 1] = 255;
                            data[index + 2] = 255;
                            data[index + 3] = 255;
                        } else {
                            data[index] = 0;
                            data[index + 1] = 0;
                            data[index + 2] = 0;
                            data[index + 3] = 255;
                        }
                    }
                }
                ctx.putImageData(imageData, 0, 0);

                const maskImageURL = canvas.toDataURL("image/png");
                this.maskImageURL = maskImageURL;

                canvas.toBlob((blob) => {
                    const maskImageFile = new File([blob], "mask_image.png", {type: "image/png"});
                    this.maskFile = maskImageFile;
                });
            };
            img.src = URL.createObjectURL(this.imageFile);

            if (start) {
                if (deleteStart === 'delete') {
                    this.deleteCleanup()
                } else {
                    this.performTextInpainting()
                }
            }
        },

        async performTextInpainting() {
            if (!this.textPrompt) {
                alert('변경하고싶은 설명을 기재해주세요.')
                return false;
            }
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);
            form.append('mask_file', this.maskFile);
            form.append('text_prompt', this.textPrompt);

            try {
                const response = await fetch('https://clipdrop-api.co/text-inpainting/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async deleteCleanup() {
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);
            form.append('mask_file', this.maskFile);

            try {
                const response = await fetch('https://clipdrop-api.co/cleanup/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async deleteBackground() {
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);

            try {
                const response = await fetch('https://clipdrop-api.co/remove-background/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async enlargementImage() {
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);
            form.append('target_width', this.targetWidth);
            form.append('target_height', this.targetHeight);

            try {
                const response = await fetch('https://clipdrop-api.co/image-upscaling/v1/upscale', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async reimagine() {
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);

            try {
                const response = await fetch('https://clipdrop-api.co/reimagine/v1/reimagine', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async deleteText() {
            this.showspinner = true;
            const form = new FormData();
            form.append('image_file', this.imageFile);

            try {
                const response = await fetch('https://clipdrop-api.co/remove-text/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async textImage() {
            if (!this.textPrompt) {
                alert('텍스트를 입력해 주세요.')
                return false;
            }
            this.showspinner = true;
            const form = new FormData();

            form.append('prompt', this.textPrompt);

            try {
                const response = await fetch('https://clipdrop-api.co/text-to-image/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        async replaceBackground() {
            if (!this.textPrompt) {
                alert('텍스트를 입력해 주세요.')
                return false;
            }
            this.showspinner = true;
            const form = new FormData();

            form.append('prompt', this.textPrompt);
            form.append('image_file', this.imageFile);

            try {
                const response = await fetch('https://clipdrop-api.co/replace-background/v1', {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey
                    },
                    body: form
                });

                if (response.ok) {
                    const blob = await response.blob();
                    this.apiResponse = URL.createObjectURL(blob);
                    this.showspinner = false;
                } else {
                    console.error('API 호출이 실패했습니다.');
                    this.showspinner = false;
                }
            } catch (error) {
                console.error('API 호출 중 오류:', error);
                this.showspinner = false;
            }
        },

        onClose() {
            this.imageUrl = null
            this.imageFile = null
            this.extendLeft = 0
            this.extendRight = 0
            this.extendUp = 0
            this.extendDown = 0
            this.apiResponse = null
            this.outputImageUrl = null
            this.isDragging = false
            this.startX = 0
            this.startY = 0
            this.endX = 0
            this.endY = 0
            this.maskImageURL = ''
            this.maskFile = ''
            this.isDrawing = false
            this.lastX = 0
            this.lastY = 0
            this.selectedRegion = null
            this.textPrompt = ''
        },
    }
}
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.image {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;
}
</style>
